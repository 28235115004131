<template>
  <div id="app" :class="`h-screen ${!hideObsBanner ? 'bg-[#E5E7EB]' : 'bg-[rgba(0, 0, 0, 0)]'}`">
    <div class="flex justify-center">
      <div
        class="inline-flex flex-col sm:flex-row justify-center text-center my-10 shadow-lg rounded-lg bg-white overflow-hidden">

        <h1 class="font-bold tabular-nums text-5xl sm:text-7xl lg:text-9xl p-2 my-auto">{{ prettyDate }}</h1>

        <TimerBall direction="horizontal" class="mx-auto py-2 sm:hidden" :timestamp="now" :sec="sec" :millis="millis"></TimerBall>
        <TimerBall direction="vertical" class="mr-3 py-2 hidden sm:block" :timestamp="now" :sec="sec" :millis="millis"></TimerBall>

        <div class="bg-gray-300 w-full sm:w-auto">
          <div class="flex py-2 px-3 flex-row sm:flex-col h-full justify-center align-middle text-center tabular-nums">
            <div class="flex flex-col">
              <div class="font-semibold">Offset:</div>
              <div>{{ prettyOffset > 0 ? '+' : '' }}{{ prettyOffset }}s</div>
            </div>
            <a class="ml-5 sm:ml-0 my-auto sm:my-0 sm:mt-2" href="https://zusor.io/" target="_blank" rel="noopener">
              <img class="h-10 mx-auto" src="./assets/logo_split.svg" alt="Zusor Logo">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mx-2">
      <div v-if="!hideObsBanner" class="flex-inline flex-col ">
        <a class="px-5 py-3 rounded-md shadow-md bg-blue-800 text-white inline-flex flex-row align-middle gap-x-3"
           :href="obsUrl">
          <img class="h-8" src="./assets/obs_logo.svg" alt="Zusor Logo">
          <div class="ml-1 my-auto text-xl">
            Drag me into OBS
          </div>
        </a><br>
        <label class="inline-flex items-center mt-3">
          <input type="checkbox" v-model="enableBeep" class="form-checkbox rounded-md h-5 w-5 text-gray-600"><span class="ml-2">Beep every second</span>
        </label>
        <div class="font-bold">URL Parameters</div>
        <p class="leading-loose">
          Append <code class="p-1 bg-gray-700 rounded-lg text-white">?beep</code> to the URL to beep every second<br>
          Append <code class="p-1 bg-gray-700 rounded-lg text-white">?embed</code> to hide this<br>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import {create} from "timesync";
import {DateTime} from "luxon";
import TimerBall from "@/components/TimerBall";


export default {
  name: 'App',
  components: {TimerBall},
  data: () => {
    const ts = create({
      server: '/timesync',
      interval: 10000
    })
    return {
      ts,
      now: ts.now(),
      hideObsBanner: !!window.obsstudio || location.search.includes("embed"),
      obsUrl: `${location.origin}/?layer-name=syncer.live&layer-width=1920&layer-height=1080&embed`,
      audioCtx: new window.AudioContext,
      enableBeep: location.search.includes("beep")
    }
  },
  created() {
    this.now = this.ts.now()
    console.log(`Created at ${this.now} / ${this.millis}`)
    setInterval(() => {
      setTimeout(() => {
        this.beep()
      }, 1000 - this.millis)
    }, 1000)

    setInterval(() => {
      this.now = this.ts.now()
    }, 5)
  },
  computed: {
    prettyDate() {
      return DateTime.fromMillis(this.now).setZone('UTC').toFormat("HH:mm:ss.SSS").slice(0, -2)
    },
    prettyOffset() {
      return (this.ts.offset / 1000).toFixed(4)
    },
    timestampString() {
      return Math.round(this.now).toString()
    },
    sec() {
      return parseInt(this.timestampString.charAt(this.timestampString.length - 4))
    },
    millis() {
      return parseInt(this.timestampString.substr(this.timestampString.length - 3, 3))
    },
  },
  methods: {
    beep() {
      if (!this.enableBeep) return;
      const oscillator = this.audioCtx.createOscillator();
      const gainNode = this.audioCtx.createGain();

      oscillator.connect(gainNode);
      gainNode.connect(this.audioCtx.destination);

      oscillator.frequency.value = 600;

      oscillator.start(this.audioCtx.currentTime);
      oscillator.stop(this.audioCtx.currentTime + 0.05);
    }
  }
}
</script>

