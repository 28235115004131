<template>
  <div>
    <vue-p5 v-on="{ setup, draw }"></vue-p5>
  </div>
</template>

<script>
import VueP5 from 'vue-p5';

export default {
  name: "TimerBall",
  data: () => ({
    colors: [
      "#312E81",
      "#EF4444",
      "#3B82F6",
      "#F59E0B",
      "#F9A8D4",
      "#10B981",
      "#1F2937",
      "#AEEA00",
      "#3E2723",
      "#DD2C00",
    ]
  }),
  components: {
    "vue-p5": VueP5
  },
  props: ["timestamp", "direction", "sec", "millis"],
  methods: {
    setup(sketch) {
      if (this.direction === "vertical") {
        sketch.resizeCanvas(50, 120);
      } else if (this.direction === "horizontal") {
        sketch.resizeCanvas(220, 50);
      }
    },
    draw(sketch) {
      sketch.clear()
      sketch.fill(this.colors[this.sec])
      if (this.direction === "vertical") {
        const pos = Math.abs(this.millis - 500) / 5
        sketch.rect(0, pos, 50, 20)
      } else if (this.direction === "horizontal") {
        const pos = Math.abs(this.millis - 500) / 2.5
        sketch.rect(pos, 0, 20, 50)
      }
    }
  }
}
</script>
